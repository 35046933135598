<template>
  <div>
    <el-card>
      <!--      刊例调整说明-->
      <div slot='header' class='clearfix'>
        <el-button style='float: left; padding: 3px 0' type='text' :disabled='loading'
                   :loading='loading' @click='initArticleForm'>刷新<i class='el-icon-refresh'></i>
        </el-button>
        <el-button style='float: right; padding: 3px 0' type='text' :disabled='loading'
                   @click='editButton(articleId)' v-if="userPermissions.indexOf('plc_notice_edit')>-1">
          修改<i class='el-icon-edit-outline'></i></el-button>
      </div>
      <div>
        <el-row>
          <el-col :span='24' style='text-align: center'>
            <span class='title'>{{ formData.title || '' }}</span>
          </el-col>

        </el-row>
        <div v-html="formData.content||'<span>暂无内容</span>'"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PlcNotice',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      loading: false,
      articleId: null,
      //文章信息
      formData: {}
    }
  },
  methods: {
    async editButton(id) {
      // this.$router.push(this.$route.path+'/'+id+'/edit')
      const { href } = this.$router.resolve({
        name: 'article-edit',
        params: { article_id: id }
      })
      window.open(href, '_blank')
    },
    // 初始化文章基本信息
    async initArticleForm() {
      if (this.articleId) {
        let { info } = await this.$api.getArticleInfo(this.articleId)
        this.formData = info
      }
    },
    async getPlcArtistId() {
      //获取缇苏刊例调整说明的文章ID
      let { article_id } = await this.$api.getPublicationArticleID()
      if (article_id) {
        this.articleId = article_id
      }

    }
  },
  async mounted() {
    await this.getPlcArtistId()
    setTimeout(() => {
      this.initArticleForm()
    }, 500)

  }
  // async created() {
  //
  // }
}
</script>

<style scoped>
.title {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}
</style>
