<template>
  <div>
    <el-dialog :title='title'
               :visible.sync='visible'
               direction='rtl'
               width='900px'>
      <el-form :model='formItemData'>
        <el-form-item>
          <ApeEditor
            :inline='true'
            :init-html='formItemData.bs_case'
            :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify ' +
          '  | removeformat anchor hr |' +
          ' code preview fullscreen'"
            @handleTinymceInput='handleTinymceInput'></ApeEditor>
        </el-form-item>
        <el-form-item style='text-align: center'>
          <el-button type='primary' size='medium' @click='handleSave'>保存</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
import ApeEditor from '@/components/ApeEditor'
import { html2json } from 'html2json'

export default {
  name: 'BsCaseEditor',
  components: { ApeEditor },
  props: {},
  data() {
    return {
      visible: false,
      title: '修改商务案例',
      formItemData: { bs_case: '' }
    }
  },
  methods: {
    show(row) {
      this.title = `修改【${row.year}年${row.month}月 ${row.nickname}】刊例提报-商务案例`
      this.formItemData = { ...row }
      this.visible = true
    },
    //编辑器
    handleTinymceInput(val) {
      this.formItemData.bs_case = val
    },
    async handleSave() {
      // 修改刊例信息-指定只修改商务案例
      await this.$api.sampleSavePublicationItem(this.formItemData.id, {
        'bs_case': this.formItemData.bs_case,
        bs_case_rich: html2json(this.formItemData.bs_case)
      })
      this.$emit('handleSaved')
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
