<template>
  <div>
    <div class='fl-row'>
      <!--      前面追加-->
      <slot name='prev-slot'></slot>
      <!--      自动渲染-->
      <template v-for='(f,index) in formRenderStruct'  >
        <el-form-item :label='calcColName(f.field,f.title)' v-if='f.is_auto' :label-width='label_width'
                      :prop='f.field' :key='`${f.field}_${index}`' class='fl-col'>
          <div>
            <template v-if='f.showSlot'>
              <slot :name='f.slotName' />
            </template>
            <div v-else>
              <el-input type='number' :style='`width: ${f.width}`' v-if='f.type==="number"'
                        v-model='formData[f.field]'
                        :placeholder='f.placeholder' clearable></el-input>
              <el-input :style='`width: ${f.width|| "360px"}`' v-if='f.type==="input"' v-model='formData[f.field]'
                        :placeholder='f.placeholder' clearable></el-input>
              <el-select :style='`width: ${f.width}`' v-else-if='f.type==="select"' filterable
                         allow-create
                         v-model='formData[f.field]'
                         :placeholder='f.placeholder' clearable>
                <el-option v-for='(option,idx) in f.options'
                           :value='option.value' :label='option.label' :key='idx'></el-option>
              </el-select>
              <el-switch v-else-if='f.type==="switch"'
                         v-model='formData[f.field]'
                         active-value='Y'
                         :active-text='f.switchMap["Y"].label'
                         inactive-value='N'
                         :inactive-text='f.switchMap["N"].label'></el-switch>
            </div>
          </div>
        </el-form-item>
      </template>
      <!--      默认追加-->
      <slot />
      <!--      最后追加-->
      <slot name='suffix-slot'></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormRender',
  props: {
    formData: {
      type: Object
    },
    formRenderStruct: {
      type: Array,
      default() {
        return [
          {
            'title': '粉丝（万）',
            'field': 'fans_total',
            'type': 'number',
            'width': '50%',
            'placeholder': '粉丝数导出时格式化，单位：万个',
            'is_auto': true,
            'slotName': 'fans',
            'showSlot': true
          },
          {
            'title': '预估粉丝数',
            'field': 'pre_fans_total',
            'placeholder': '下月底预估粉丝量，必填（单位：个）',
            'type': 'number',
            'is_auto': true
          },
          {
            'title': '开通星图',
            'field': 'opened_star',
            'type': 'switch',
            'is_auto': true,
            'switchMap': {
              'Y': {
                'label': '开通',
                'value': 'Y'
              },
              'N': {
                'label': '未开通',
                'value': 'N'
              }
            }
          }
        ]
      }
    },
    setting: {
      type: Object,
      default() {
        return {
          columns_data: []
        }
      }
    }
  },
  data() {
    return {
      label_width: '150px',
      input_width: '360px'
    }
  },
  methods: {
    // eslint-disable-next-line
    calcColName(field, title) {
      let columns = this.setting.columns_data
      let obj = columns.find(value => value.field === field)
      return obj ? obj.title : title
    }
  }
}
</script>

<style scoped>
.fl-row * {
  box-sizing: border-box;
}

.fl-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

}

.fl-row .fl-col {
  width: 49%;
}
</style>
