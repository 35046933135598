<template>
  <div>
    <el-form :model='formData' label-width='100px'>
      <el-form-item label='标题'>
        <el-input v-model='formData.title' placeholder='标题' />
      </el-form-item>
      <el-form-item label='开始日期'>
        <el-date-picker
            v-model='formData.validity_date'
            type='date'
            format='yyyy年MM月dd日'
            value-format='yyyy-MM-dd'
            placeholder='有效期：开始日期'>
        </el-date-picker>
      </el-form-item>
      <el-form-item label='截止日期'>
        <el-date-picker
            v-model='formData.expiry_date'
            type='date'
            format='yyyy年MM月dd日'
            value-format='yyyy-MM-dd'
            placeholder='有效期：截止日期'>
        </el-date-picker>
      </el-form-item>
      <el-form-item label='合作须知'>
        <ApeEditor
            v-if="editorInit"
            :inline="true"
            :init-html="formData.notice"
            :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify | bullist numlist' +
          ' | outdent indent blockquote | removeformat anchor hr |' +
          ' code preview fullscreen'"
            @handleTinymceInput="handleTinymceInput"></ApeEditor>
      </el-form-item>
      <!--      <el-form-item>-->
      <!--        <div v-html='formData.notice'>-->
      <!--        </div>-->
      <!--      </el-form-item>-->

      <el-form-item>
        <el-button type='primary' @click='saveInfo' :disabled='loading' :loading='loading'>保存
        </el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import ApeEditor from '@/components/ApeEditor'
import { html2json } from 'html2json'


export default {
  components: { ApeEditor },
  name: 'PublicationEdit',
  data() {
    return {
      id: null,
      formData: { id: null, validity_date: null, expiry_date: null, notice: null, title: null },
      loading: false,
      editorInit: false
    }
  },
  methods: {
    async show(id) {
      this.formData = {}
      this.id = id
      this.editorInit = true

      await this.getInfo()

    },
    //编辑器
    handleTinymceInput(val) {
      this.formData.notice = val
    },
    async getInfo() {
      let { info } = await this.$api.getPublicationInfo(this.id)

      this.$nextTick(() => {
        this.formData = info || {}

        setTimeout(() => {
          if (!this.formData.notice) {
            this.getPlcLast(info.id)
          }
        }, 500)
      })
    },
    async getPlcLast(id) {
      if (!this.formData.notice) {
        this.$notify.warning('当前《合作须知》为空，将加载上一版的《合作须知》')
        //若本期的刊例合作须知未录入，则采用上一期
        let { info } = await this.$api.getPublicationLast(id)
        if (info.notice) {
          this.formData.notice = info.notice
          // this.$notify.info('加载上月的合作须知如下')
        }
      }
    },
    async saveInfo() {
      this.loading = true
      let params = {
        id: this.formData.id,
        title: this.formData.title,
        notice: this.formData.notice,
        expiry_date: this.formData.expiry_date,
        validity_date: this.formData.validity_date,
        notice_rich: html2json(this.formData.notice)
      }

      let id = await this.$api.savePublicationInfo(params)

      if (id) {
        this.editorInit = false

        this.$notify.success('保存成功')
        // await this.getInfo()
        this.$emit('saved')
      }
      this.loading = false
    }
  },

}
</script>

<style scoped>

</style>
